<script setup lang="ts">
import IconClose from '~~/assets/svg/icons/close.svg';
import IconShvrCoin from '~~/assets/svg/icons/shvr-coin.svg';
import IconInfo from '~~/assets/svg/icons/info.svg';
import IconTarget from '~~/assets/svg/icons/target.svg';

import CoinSetting, { CoinSettingAttrs } from '~~/utils/models/CoinSetting';
const coinSettings = ref<CoinSettingAttrs[]>();

const props = defineProps<{ show: boolean }>();
const emit = defineEmits<{ (e: 'update:show', show: boolean): void }>();
onBeforeMount(() => {
  CoinSetting.fetch().then(({ data }) => (coinSettings.value = data));
});
</script>

<template>
  <Modal
    class="!p-0"
    size="416px"
    :show="show"
    @update:show="emit('update:show', $event)"
    :static="false"
  >
    <div class="p-24">
      <div v-if="$auth.person">
        <p class="text-20 font-bold leading-28">Your SHVR Coins</p>
        <p
          class="info font-bold flex items-center p-8 border border-solid border-primary gap-8 mb-24"
        >
          <IconShvrCoin />
          {{
            new Intl.NumberFormat().format($auth.person?.coinsAvailable)
          }}
          Coins
        </p>
      </div>

      <p class="leading-26 text-18 flex gap-8 font-bold mb-16">
        <IconInfo />
        <span>About SHVR Coins</span>
      </p>

      <p class="text-16 leading-24 text-[#FFFFFF]/80 mb-24">
        The more SHVR Coins you collect, the greater your chance to get special
        merchandise from SHVR. Let's see how below!
      </p>

      <p class="leading-26 text-18 flex gap-8 font-bold mb-16">
        <IconTarget />
        <span>How to get it</span>
      </p>

      <p class="text-16 leading-24 text-[#FFFFFF]/80 mb-0">
        The more SHVR Coins you collect, the greater your chance to get special
        merchandise from SHVR. Let's see how below!
      </p>
    </div>

    <LazyModalCoinSettingCarousel
      class="mb-16"
      v-if="coinSettings?.length"
      :coinSettings="coinSettings"
    />

    <div
      class="px-24 py-8 bg-[#010E1A] border border-primary border-solid flex gap-16"
    >
      <RouterLink
        class="btn btn-outline-primary text-primary btn-sm whitespace-nowrap text-14 font-bold leading-22 bg-[#132A3E]"
        to="/shvrcoin"
        @click="emit('update:show', false)"
      >
        Exchange Coins
      </RouterLink>

      <RouterLink
        class="btn btn-custom w-full flex gap-8 text-14 font-bold leading-22"
        to="/articles"
        @click="emit('update:show', false)"
      >
        <IconShvrCoin />
        Get SHVR Coins
      </RouterLink>
    </div>

    <template #after>
      <button
        class="btn bg-white/10 backdrop-blur fixed top-0 right-0"
        @click="emit('update:show', false)"
      >
        <IconClose />
      </button>
    </template>
  </Modal>
</template>

<style scoped lang="postcss">
:deep(> .container) {
  @media (max-width: calc(theme('screens.lg') - 1px)) {
    padding: 0;
  }
}
.info {
  background: linear-gradient(180deg, #004e5e 20.31%, #10a8c6 100%);
}

.btn-custom {
  background: linear-gradient(
      90deg,
      rgba(57, 185, 210, 0.96) 0%,
      rgba(0, 132, 212, 0.8) 34.34%,
      rgba(0, 132, 212, 0.1) 100%
    ),
    theme(colors.input.bg);
}
</style>

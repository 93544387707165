<script setup lang="ts">
import { useAlertStore } from '~/store/alert';
import IconCheckmark from '~~/assets/svg/icons/checkmark.svg';

const store = useAlertStore();
const data = toRef(store, 'data');

const handleOKClick = () => {
  data.value?.onOK && data.value?.onOK();
  store.reset();
};
</script>

<template>
  <SlackMount :condition="!!data">
    <LazyModal
      class="text-center"
      size="373px"
      :show="!!data"
      :static="true"
      @update:show="$event || store.reset()"
    >
      <template v-if="data">
        <IconCheckmark class="mb-24" v-if="data.type === 'success'" />
        <h5 class="text-24 leading-32">{{ data.message }}</h5>
        <p class="opacity-75 mt-8 mb-0" v-if="data.desc">{{ data.desc }}</p>
        <button class="btn btn-primary btn-325x48 mt-24" @click="handleOKClick">
          {{ data.cta ?? 'OK' }}
        </button>
      </template>
    </LazyModal>
  </SlackMount>
</template>

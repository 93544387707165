<script setup lang="ts">
import IconFacebook from '~~/assets/svg/icons/facebook.svg';
import IconInstagram from '~~/assets/svg/icons/instagram.svg';
import IconSpotify from '~~/assets/svg/icons/spotify.svg';
import IconYoutube from '~~/assets/svg/icons/youtube.svg';

const links = [
  { label: 'FAQ', to: '/faq' },
  { label: 'Term of Use', to: '/terms-of-use' },
  { label: 'Privacy Policy', to: '/privacy-policy' },
  { label: 'Contact', to: '/contact' },
];
const socialLinks = [
  {
    label: 'Facebook',
    to: 'https://www.facebook.com/SHVRid/',
    icon: IconFacebook,
  },
  {
    label: 'Instagram',
    to: 'https://instagram.com/shvr_id',
    icon: IconInstagram,
  },
  {
    label: 'Youtube',
    to: 'https://www.youtube.com/channel/UCD3yCja5ZTJ0b9f6ob2nzRw',
    icon: IconYoutube,
  },
  {
    label: 'Spotify',
    to: 'https://open.spotify.com/user/026xv96z8kov55odkjdlx9vtr?si=G09844B2QMOKc7DsN9EpCw&dl_branch=1',
    icon: IconSpotify,
  },
];
</script>

<template>
  <footer class="text-center">
    <div class="container">
      <picture
        class="block mb-24 drop-shadow-[0_2px_12px_rgba(87,214,252,0.4)]"
      >
        <source
          media="(max-width: 1023px)"
          type="image/webp"
          :srcset="`
            ${$img('/assets/img/shvr.png', { height: 28, format: 'webp' })},
            ${$img('/assets/img/shvr.png', {
              height: 56,
              format: 'webp',
            })} 2x
          `"
        />
        <source
          type="image/webp"
          :srcset="`
            ${$img('/assets/img/shvr.png', { height: 40, format: 'webp' })},
            ${$img('/assets/img/shvr.png', {
              height: 80,
              format: 'webp',
            })} 2x
          `"
        />
        <source
          type="image/png"
          :srcset="$img('/assets/img/shvr.png', { height: 40 })"
        />
        <img
          alt="SHVR"
          width="105"
          height="28"
          :src="$img('/assets/img/shvr.png', { height: 28 })"
        />
      </picture>

      <nav
        class="text-14 lg:text-16 leading-22 lg:leading-32 font-bold flex justify-center gap-24 mb-24"
      >
        <RouterLink
          class="no-underline hover:underline"
          v-for="(link, i) of links"
          :key="i"
          :to="link.to"
        >
          {{ link.label }}
        </RouterLink>
      </nav>

      <div class="flex justify-center gap-16 mb-24 lg:mb-32">
        <a
          class="bg-[#051F2F] hover:bg-input-bg p-12 lg:p-16 rounded-full transition-colors duration-300"
          target="_blank"
          v-for="(link, i) of socialLinks"
          :aria-label="link.label"
          :key="i"
          :href="link.to"
        >
          <component :is="link.icon" />
        </a>
      </div>

      <hr class="border-primary opacity-20 mb-24 lg:mb-32" />

      <p
        class="text-[#DBEDFF]/75 text-14 lg:text-16 leading-22 lg:leading-24 pb-24 lg:pb-32 mb-0"
      >
        © SHVR {{ new Date().getFullYear() }}. ALL RIGHTS RESERVED.
      </p>
    </div>
  </footer>
</template>

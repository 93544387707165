<script setup lang="ts">
import IconShvrCoin from '~~/assets/svg/icons/shvr-coin.svg';
import IconClosePrimary from '~~/assets/svg/icons/close-primary.svg';
import LineClip from '~~/assets/svg/lines/clip.svg';

import { useNotificationStore } from '~/store/notification';

const store = useNotificationStore();
const data = toRef(store, 'data');

const handleOKClick = () => {
  data.value?.onOK && data.value?.onOK();
  store.reset();
};
</script>

<template>
  <SlackMount :condition="!!data">
    <LazyNotification
      class="!p-0"
      size="427px"
      :show="!!data"
      :static="true"
      @update:show="$event || store.reset()"
    >
      <template v-if="data">
        <div class="clip bg-primary p-1">
          <div class="p-16 flex gap-12 justify-between clip bg-[#051F2F]">
            <IconShvrCoin width="40" height="40" class="shrink-0" />
            <div>
              <p class="text-16 font-bold mb-8">
                Congratulations! You get
                {{ new Intl.NumberFormat().format(data.desc) }} Coins!
              </p>
              <p class="text-14 text-[#FFFFFF]/80 mb-0">
                Come on, keep exploring to get lots of coins to exchange
                interesting merchandise
              </p>
            </div>
            <button class="btn p-0 h-fit" @click="handleOKClick">
              <IconClosePrimary width="24" height="24" class="shrink-0" />
            </button>
          </div>
        </div>
        <LineClip class="block -mt-1 -ml-1" />
      </template>
    </LazyNotification>
  </SlackMount>
</template>

<style scoped lang="postcss">
:deep(> .container) {
  @media (max-width: calc(theme('screens.lg') - 1px)) {
    padding: 0;
  }
}
</style>

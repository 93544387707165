<template>
  <div class="bg flex justify-center fixed inset-0 -z-1">
    <picture class="bg-wave block mt-[18.88%]">
      <source
        type="image/webp"
        :srcset="`
          ${$img('/assets/img/bg-wave.png', { width: 1440, format: 'webp' })},
          ${$img('/assets/img/bg-wave.png', { width: 2880, format: 'webp' })} 2x
        `"
      />
      <img alt="" :src="$img('/assets/img/bg-wave.png', { width: 1440 })" />
    </picture>
  </div>
</template>

<style scoped lang="postcss">
@keyframes bg-wave {
  0%,
  100% {
    transform: rotate(-5deg);
  }

  50% {
    transform: rotate(5deg);
  }
}

.bg {
  background: url(/assets/img/bg.svg) top/cover var(--bs-body-bg);

  @media screen(md) {
    background-image: url(/assets/img/bg-md.svg);
  }
}

.bg-wave {
  animation: bg-wave 6s ease infinite;
}
</style>

<template>
  <div class="site">
    <!-- Google Tag Manager (noscript) -->

    <!-- End Google Tag Manager (noscript) -->

    <Transition name="fade">
      <LazyLayoutBG2 v-if="infoStore.hasInteraction" />
    </Transition>

    <LayoutHeader />

    <slot />

    <LayoutFooter class="mt-84 lg:mt-[124px]" />

    <LazyLayoutGHW class="sticky bottom-0 z-1" v-if="$route.meta.ghw" />

    <ClientOnly>
      <Teleport to="body">
        <ModalAgeGate />
        <ModalAlert />
        <NotificationCoinsEarned />
      </Teleport>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import { useInfoStore } from '~~/store/info';

const infoStore = useInfoStore();

</script>

<script setup lang="ts">
import dayjs from 'dayjs';
import LineHorizontalGradient3 from '~~/assets/svg/lines/horizontal-gradient-3.svg';

const { $auth } = useNuxtApp();
const confirmedCookie = useCookie('age/confirmed', {
  expires: dayjs().add(1, 'day').toDate(),
});
const input = ref('');
const error = ref<string>();
const showModal = ref(!$auth.loggedIn && !confirmedCookie.value);

const validate = () => {
  if (!input.value) return 'Bulan dan tahun lahir harus diisi.';

  const date = dayjs(input.value);
  const now = dayjs();

  if (now.diff(date, 'year') < 18) {
    return 'Pastikan umur kamu lebih dari 18 tahun ya buat lanjutin.';
  }
};

const handleSubmit = () => {
  error.value = validate();

  if (!error.value) {
    showModal.value = false;
    confirmedCookie.value = '1';
  }
};
</script>

<template>
  <SlackMount :condition="showModal">
    <LazyModal
      class="border border-solid border-primary"
      size="360px"
      v-model:show="showModal"
      :static="true"
    >
      <h6 class="text-24 leading-28 text-center mb-24">
        Pastikan usia kamu sudah berumur lebih dari 18 tahun?
      </h6>
      <LineHorizontalGradient3 class="block mx-auto mb-24" />

      <form @submit.prevent="handleSubmit">
        <div class="mb-24">
          <FormDateField
            v-model="input"
            :class="{ 'is-invalid': error }"
            disableDay
          />
          <div class="invalid-feedback">{{ error }}</div>
        </div>

        <button class="btn btn-primary btn-295x48 lg:btn-328x48">
          Konfirmasi
        </button>
      </form>

      <div class="invalid-feedback">{{ error }}</div>
    </LazyModal>
  </SlackMount>
</template>

<script setup lang="ts">
/**
 * Lazily mount element based on a condition.
 *
 * The element will be mounted once the condition is true, then ignore next
 * condition changes. Useful to lazy load dynamic elements with animation.
 */

const props = defineProps<{
  /**
   * Condition to mount the element.
   */
  condition: boolean;
}>();
const didShow = ref(false);

watch(
  () => props.condition,
  (condition) => {
    if (condition) {
      didShow.value = true;
    }
  },
  { immediate: true }
);
</script>

<template>
  <slot v-if="didShow" />
</template>
